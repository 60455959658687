import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const getPrefix = async () => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + "prefixes",
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const getProvince = async () => {
    let token = LocalStorage.getAccessToken();
    return axios.get(
      config.BASE_API + "provinces",
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }
    )
  }
  
  export const getAmphurs = async (id) => {
    let token = LocalStorage.getAccessToken();
    return axios.get(
      config.BASE_API + "amphurs/"+id,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }
    )
  }
  export const getTambons = async (id) => {
    let token = LocalStorage.getAccessToken();
    return axios.get(
      config.BASE_API + "tambons/"+id,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }
    )
  }