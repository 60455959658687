import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const callGetService = async (uri) => {
  let token = LocalStorage.getAccessToken();
  return axios.get(
    config.BASE_API + uri,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callPostService = async (uri, data) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + uri,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const callPutService = async (uri, data) => {
  let token = LocalStorage.getAccessToken();
  return axios.put(
    config.BASE_API + uri,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}