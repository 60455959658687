import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';

import {
  callGetService
} from '../../services/Service'

export default function PaFHistory() {
  const [org_list, setOrgList] = useState([])
  const [selected_org_id, setSelectedOrgID] = useState(0)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);

  const [data_list, setDataList] = useState([])

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedOrgID(0)
  };

  const handleEditClick = (event, cellValues) => {
    // event.preventDefault();
    // window.location.href = "/org/edit/"+cellValues.row.id
  }

  const handleDeleteClick = (event, cellValues) => {
    // event.preventDefault();
    // let org_id = cellValues.row.id
    // let org_name = cellValues.row.org_name
    // let header = "ลบข้อมูลหน่วยงาน"
    // let body = "คุณต้องการลบช้อมูล "
    // body += "หน่วยงาน "+org_name
    // setAlertHeader(header)
    // setAlertBody(body)
    // setSelectedOrgID(org_id)
    // setAlertMode("delete")
    setOpenAlert(true)
  }

  const createOrgList = () => {
    // getOrgList().then(res => {
    //   let all_org = res.data.data
    //   console.log(all_org);
    //   let temp_org_list = []
    //   all_org.map((item, index) => {
    //     temp_org_list.push({
    //       id: item.org_id,
    //       index: index + 1,
    //       org_name: item.org_name,
    //       org_code: item.org_code,
    //       org_status: item.org_status,
    //     })
    //   })
    //   setOrgList(temp_org_list)
    // }).catch(error=>{
    //   console.log(error.response);
    //   if(error.response.status === 401){
    //     window.location.href = "/auth/login";     
    //   }
    // })
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    // window.location.href = "/org/add";
  }

  const handleAlertButton = () => {
    // let obj = {
    //   org_id: selected_org_id,
    // }
    // console.log(obj);
    // callOrg("organizations/delete", obj).then(res => {
    //   handleClose()
    //   setOrgList([])
    //   createOrgList()
    // }).catch(error=>{
    //   console.log(error.response);
    //   if (error.response) {
    //     if (error.response.status === 401){
    //       window.location.href = "/auth/login";      
    //     }
    //   }
    // })
  }
  
  const columns = [
    { field: 'index', headerName: 'ลำดับ', width: 50 },
    { field: 'requested_user_id', headerName: 'รหัสผู้เรียกดูข้อมูล', width: 150 },
    { field: 'requested_user_name', headerName: 'ชื่อผู้เรียกดูข้อมูล', width: 250 },
    { field: 'responsed_user_name', headerName: 'ผู้ถูกเรียกดูข้อมูล', width: 250 },
    { field: 'responsed_province', headerName: 'จังหวัดผู้ถูกเรียกดูข้อมูล', width: 150 },
    { field: 'time', headerName: 'วันเวลาที่เรียกดูข้อมูล', width: 250 },
  ];

  const getDataGrid = () =>{
    callGetService('historys').then(res=>{
      let index = 0;
      let temp_data_list = []
      for(let row of res.data.data){
        index++;
        temp_data_list.push({
          id:row.id,
          index:index,
          requested_user_id:row.attributes.requested_user_id,
          requested_user_name:row.attributes.requested_user_name,
          responsed_user_name:row.attributes.responsed_user_name,
          responsed_province:row.attributes.responsed_province,
          time:row.attributes.createdAt,
        })
      }
      setDataList(temp_data_list)
    })
  }
  useEffect(async () => {
    getDataGrid()
  }, []);

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>ประวัติการเรียกดูข้อมูลส่วนบุคคล</Typography>
        </Grid>
        <Grid item xs={12} >
          <div style={{ height: 600, width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={data_list}
              columns={columns}
              rowsPerPageOptions={[]}
            />
          </div>
        </Grid>
      </Grid>
      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}