import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';

import {
  callGetService
} from '../../services/Service'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PaFList() {

  const [open, setOpen] = React.useState(false);
  const handleOpen2 = () => setOpen(true);
  const handleClose2 = () => setOpen(false);
  const [org_list, setOrgList] = useState([])
  const [selected_org_id, setSelectedOrgID] = useState(0)
  const [alert_header, setAlertHeader] = useState("")
  const [alert_body, setAlertBody] = useState("")
  const [alert_mode, setAlertMode] = useState("")
  const [open_alert, setOpenAlert] = useState(false);

  const [personal_list, setPersonalList] = useState([])

  const handleClose = () => {
    setOpenAlert(false);
    setSelectedOrgID(0)
  };

  const columns = [
    { field: 'index', headerName: 'ลำดับ', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'personal_name', headerName: 'ชื่อ นามสกุล', width: 250, headerAlign: 'center' },
    { field: 'total_farm_number', headerName: 'จำนวนแปลง', width: 250, headerAlign: 'center' },
    { field: 'total_farm_area', headerName: 'พื้นที่เกษตรกรรม', width: 150, headerAlign: 'center' },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleEditClick(event, cellValues);
          }} >
          <EditIcon />
        </IconButton>
      }
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 150,
      headerAlign: 'center',
      align: 'center',
      disableClickEventBubbling: true,
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ];

  const handleEditClick = (event, cellValues) => {
    event.preventDefault();

    window.location.href = "/paf/edit/" + cellValues.row.id;
  }

  const handleDeleteClick = (event, cellValues) => {
    event.preventDefault();
  }

  const handleAddClick = (event) => {
    event.preventDefault();
    window.location.href = "/paf/add";
  }

  const handleAlertButton = () => {

  }

  useEffect(() => {
    const createList = async () => {
      try {
        callGetService('personals').then(res => {
          let index = 0;
          console.log(res.data.data);
          let temp_personal_list = []
          for (let personal_data of res.data.data) {
            index++;
            let personal_prefix = personal_data.attributes.personal_prefix.data.attributes.prefix_name;
            let personal_name = personal_prefix + " " + personal_data.attributes.personal_name + " " + personal_data.attributes.personal_surname;
            let total_farm_number = 0;
            let total_farm_area = 0;
            if (personal_data.attributes.farm[0] !== undefined) {
              total_farm_number = personal_data.attributes.farm[0].data.length;
              for (let farm of personal_data.attributes.farm[0].data) {
                total_farm_area += farm.attributes.farm_area;
              }
            }

            temp_personal_list.push({
              id: personal_data.id,
              index: index,
              personal_name: personal_name,
              total_farm_number: total_farm_number,
              total_farm_area: total_farm_area
            })
          }
          console.log(temp_personal_list);
          setPersonalList(temp_personal_list)
        })
      } catch (error) {
        console.error('Error fetching data from Server:', error);
        alert('Error fetching data from Server')
      }
    };

    createList();
  }, []);

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>รายการข้อมูลส่วนบุคคล</Typography>
        </Grid>
        <Grid item >
          <Button
            variant="contained"
            onClick={handleAddClick}
          >
            เพิ่มข้อมูล
          </Button>
        </Grid>
        <Grid item style={{ marginLeft: '1rem' }}>
          <Button
            variant="contained"
            onClick={handleOpen2}
          >
            Import ข้อมูล
          </Button>
        </Grid>
        <Grid item xs={12} >
          <div style={{ width: '100%', marginTop: '2rem' }}>
            <DataGrid
              style={{ backgroundColor: "#FFFFFF" }}
              rows={personal_list}
              columns={columns}
              rowsPerPageOptions={[100]}
              autoHeight
            />
          </div>
        </Grid>
      </Grid>

      <Dialog
        open={open_alert}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {alert_header}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {alert_body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertButton} variant="contained" color="success">
            ตกลง
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            ยกเลิก
          </Button>
        </DialogActions>
      </Dialog>
      <Modal
        open={open}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Import
          </Typography>
          {/* <Grid container spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="เลขบัตรประชาชน"
                    variant="outlined"
                  // value={org_name}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  // onChange={event => setOrgName(event.target.value)}
                  />
                </Grid>
                </Grid> */}
          <Grid
            container
            spacing={2}
          >
            <Grid item xs={8}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="ไฟล์"
                variant="outlined"
                type="file"
                // value={org_name}
                InputLabelProps={{
                  shrink: true,
                }}
              // onChange={event => setOrgName(event.target.value)}
              />
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="success"
              // onClick={handleSaveButton}
              >
                บันทึก
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                variant="contained"
                color="error"
              // onClick={handleBackButton}
              >
                ย้อนกลับ
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  )
}