import React from 'react';
// import ReactDOM from 'react-dom/client'; 
import './index.css';

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Login from './pages/auth/login'
import PaFList from './pages/paf/PaFList';
import PaFHistory from './pages/paf/PaFHistory';
import PaFAdd from './pages/paf/PaFAdd';
import PaFEdit from './pages/paf/PaFEdit'

//DEV
import PaTest from './pages/paf/PaTest'


function App() { 
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth/login" element={<Login />} />
        <Route path="/paf/list" element={<PaFList />} />
        <Route path="/paf/history" element={<PaFHistory />} />
        <Route path="/paf/add" element={<PaFAdd />} />
        <Route path="/paf/import" element={<Login />} />
        <Route path="/paf/edit/:id" element={<PaFEdit/>} />

        <Route path="/paf/test" element={<PaTest/>} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;