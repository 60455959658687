/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Polyline, Marker, Polygon, Popup, Tooltip, withLeaflet, GeoJSON } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css'; // Import the Leaflet CSS

// import { Col, Row, Carousel, Button, ProgressBar, Modal, Form, Nav, Navbar, Container, NavItem, NavLink } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
const polygonRef = []

const MapComponent = () => {
    const navigate = useNavigate();
    const setColor = ({ properties }) => {
        return { weight: 1 };
    };
    const mapRef = useRef(null);

    const [geojsonData, setGeojsonData] = useState(null);

    const pointStyle = {
        position: 'absolute',
        top: '40.3%',
        left: '49.63%',
        transform: 'translate(-50%, -50%)',
        // width: '10px',
        // height: '10px',
        // borderRadius: '50%',
        // backgroundColor: 'red',
        zIndex: '9999',
    };

    // Create a Leaflet icon using the MdLocationPin icon from react-icons/md
    // const customIcon = L.icon({
    //     iconUrl: MdLocationPin,
    //     iconSize: [30, 30],
    // });

    return (
        <>
            {/* <div className="regisForm"> */}
                <div style={{ margin: '-40px 20px 0 20px', border: '1px solid black' }}>
                    <MapContainer
                        center={[13.7367, 100.5231]}
                        zoom={13}
                        style={{ width: '100%', height: '350px' }}
                        ref={mapRef}
                    >
                        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                        {/* Add GeoJSON component to display your data */}
                        {/* <GeoJSON data={seg4} style={setColor} onEachFeature={onEachFeature} /> */}
                        {/* {geojsonData && <GeoJSON data={geojsonData} style={setColor} onEachFeature={onEachFeature} />} */}
                    </MapContainer>
                </div>
            {/* </div > */}
        </>
    );
};

export default MapComponent;
