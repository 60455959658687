import React, { useState, useEffect, useRef } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
// import PaFForm from '../../components/PaFForm';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import "leaflet/dist/leaflet.css"
import "leaflet-draw/dist/leaflet.draw.css"
import { MapContainer, TileLayer, FeatureGroup, useMapEvents, Popup, Marker, Tooltip, Polygon } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { polygon, area } from '@turf/turf';
import Loading from '../../components/loading.js';

import {
  useLocation
} from 'react-router-dom';

import {
  editPersonal
} from '../../services/Personal'

import {
  callGetService,
  callPostService,
  callPutService
} from '../../services/Service'

import {
  getPrefix,
  getProvince,
  getAmphurs,
  getTambons
} from '../../services/Dropdown'
import { useAsyncError } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PaFEdit() {
  const mapRef = useRef(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [markerCoordinates, setMarkerCoordinates] = useState([]);
  const [polygonCoordinates, setPolygonCoordinates] = useState([]);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [pinCoordinates, setPinCoordinates] = useState(null);

  const location = useLocation();
  // form variable
  const [personal_id, setPersonalID] = useState("");
  const [prefix_list, setPrefixList] = useState([]);
  const [selected_prefix, setSelectedPrefix] = useState(0);
  const [personal_name, setPersonalName] = useState("");
  const [personal_surname, setPersonalSurname] = useState("");
  const [personal_birthday, setPersonalBirthday] = useState("");
  const [personal_phone, setPersonalPhone] = useState("");
  const [is_farmer, setIsFarmer] = useState(false);
  const [personal_addr, setPersonalAddr] = useState("");
  const [personal_moo, setPersonalMoo] = useState("");
  const [personal_alley, setPersonalAlley] = useState("");
  const [personal_lane, setPersonalLane] = useState("");
  const [personal_road, setPersonalRoad] = useState("");
  const [tambon_default, setTambonDefault] = useState("กรุณาเลือกจังหวัด");
  const [tambon_list, setTambonList] = useState([]);
  const [selected_tombon, setSelectedTambon] = useState(0);
  const [amphur_default, setAmphurDefault] = useState("กรุณาเลือกจังหวัด");
  const [amphur_list, setAmphurList] = useState([]);
  const [selected_amphur, setSelectedAmphur] = useState(0);
  const [province_list, setProvinceList] = useState([]);
  const [selected_province, setSelectedProvince] = useState(0);
  const [farmer_id, setFarmerID] = useState(0);
  const [is_refresh, setIsRefresh] = useState(false);

  const [is_edit, setIsEdit] = useState(false)
  const [data, setData] = useState()

  // table variable
  const [rows_data, setRowsData] = useState([])
  const farm_columns = [
    { field: 'index', headerName: 'ลำดับ', width: 100, headerAlign: 'center', align: 'center' },
    { field: 'farm_name', headerName: 'ชื่อแปลง', width: 350, headerAlign: 'center' },
    { field: 'farm_rai', headerName: 'ไร่', width: 150, headerAlign: 'center' },
    { field: 'farm_nga', headerName: 'งาน', width: 150, headerAlign: 'center' },
    { field: 'farm_wa', headerName: 'วา', width: 150, headerAlign: 'center' },
    { field: 'farm_area', headerName: 'ตารางเมตร', width: 150, headerAlign: 'center' },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 100,

      headerAlign: 'center',
      align: 'center',
      renderCell: ({ row }) =>
        <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={
            () => {
              console.log('row', row);
              setSelectedFarm(row.id);
              setIsEdit(true);
              setMapLayers(row.farm_geo_fence); setIsModalOpen(true); setFarmName(row.farm_name); setFarmRai(row.farm_rai); setFarmNga(row.farm_nga); setFarmWa(row.farm_wa); setFarmArea(row.farm_area);
            }}>
          <EditIcon />
        </IconButton>,
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (cellValues) => {
        return <IconButton
          color="secondary"
          aria-label="add an alarm"
          onClick={(event) => {
            // handleDeleteClick(event, cellValues);
          }} >
          <DeleteIcon />
        </IconButton>
      }
    }
  ]

  // modal vaiable
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [farm_name, setFarmName] = useState("");
  const [farm_rai, setFarmRai] = useState(0);
  const [farm_nga, setFarmNga] = useState(0);
  const [farm_wa, setFarmWa] = useState(0);
  const [farm_area, setFarmArea] = useState(0);
  const [selected_farm, setSelectedFarm] = useState(0)

  // const [geojsonData, setGeojsonData] = useState([]);
  const [receivedData, setReceivedData] = useState('');
  const [mapLayers, setMapLayers] = useState([]);

  const handleDataFromChild = (data) => {
    setReceivedData(data);
    setFarmRai((parseInt(data / 1600)));
    setFarmNga((parseInt(data % 1600) / 400));
    setFarmWa((parseInt(data % 400) / 4));
    setFarmArea(parseInt(data));
  };

  const handleDataFromChild2 = (data) => {
    console.log(data)
    setMapLayers(data)
  }

  const handleBackButton = () => window.location.href = "/paf/list";

  const handleChangeToggle = (event) => {
    setIsFarmer(event.target.checked);
    let id = location.pathname.split('/')[3]
    let data = {
      data: {
        farmer_uid: uuid(),
        personal: parseInt(id)
      }
    }
    callPostService("farmers", data).then(res => {
      console.log("didi");
      // setIsRefresh(!is_refresh);
    })
  };

  const handleSaveButton = () => {
    if (selected_prefix === 0) {
      alert("คุณยังไม่ได้เลือกคำนำหน้าชื่อ \n กรุณาเลือกคำนำหน้าชื่อ")
    } else if (personal_name === "") {
      alert("คุณยังไม่ได้ระชุชื่อ \n กรุณาระบุชื่อ")
    } else if (personal_surname === "") {
      alert("คุณยังไม่ได้ระบุนามสกุล \n กรุณาระบุนามสกุล")
    } else if (personal_id === "") {
      alert("คุณยังไม่ได้ระบุรหัสประชาชน \n กรุณาระบุรหัสประชาชน")
    } else if (personal_addr === "") {
      alert("คุณยังไม่ได้ระบุบ้านเลขที่ \n กรุณาระบุบ้านเลขที่")
    } else if (selected_tombon === 0) {
      alert("คุณยังไม่ได้เลือกตำบล \n กรุณาเลือกตำบล")
    } else if (selected_amphur === 0) {
      alert("คุณยังไม่ได้เลือกอำเภอ \n กรุณาเลือกอำเภอ")
    } else if (selected_province === 0) {
      alert("คุณยังไม่ได้เลือกจังหวัด \n กรุณาเลือกจังหวัด")
    } else if (personal_birthday === "") {
      alert("คุณยังไม่ได้ระบุวันเกิด \n กรุณาระบุวันเกิด")
    } else {
      let add_object = {
        data: {
          "personal_id": personal_id,
          "personal_prefix": selected_prefix,
          "personal_name": personal_name,
          "personal_surname": personal_surname,
          "personal_birthday": personal_birthday,
          "personal_phone": personal_phone,
          "personal_addr": personal_addr,
          "personal_moo": personal_moo,
          "personal_alley": personal_alley,
          "personal_lane": personal_lane,
          "personal_road": personal_road,
          "tambon": selected_tombon,
          "amphur": selected_amphur,
          "province": selected_province
        }
      }
      let id = location.pathname.split('/')[3]
      editPersonal(id, add_object).then(res => {
        alert("แก้ไขข้อมูลสำเร็จ")
        window.location.href = "/paf/list";
      })
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let id = location.pathname.split('/')[3]

        await getPrefix().then(res => {
          let temp_prefix_list = []
          let prefix_res_data = res.data.data
          for (let prefix_row_data of prefix_res_data) {
            temp_prefix_list.push({
              id: prefix_row_data.id,
              name: prefix_row_data.attributes.prefix_name
            })
          }
          setPrefixList(temp_prefix_list)
        })

        await getProvince().then(res => {
          let temp_list = []
          let res_data = res.data.data
          for (let row_data of res_data) {
            temp_list.push({
              id: row_data.id,
              name: row_data.attributes.province_name
            })
          }
          setProvinceList(temp_list)
        })

        var persnoal_data2;
        persnoal_data2 = await callGetService("personals/" + id).then(res => {
          let persnoal_data = res.data.data[0].attributes
          setPersonalName(persnoal_data.personal_name)
          setPersonalSurname(persnoal_data.personal_surname)
          setPersonalID(persnoal_data.personal_id)
          setPersonalBirthday(persnoal_data.personal_birthday)
          setPersonalPhone(persnoal_data.personal_phone)
          setPersonalAddr(persnoal_data.personal_addr)
          setPersonalMoo(persnoal_data.personal_moo)
          setPersonalAlley(persnoal_data.personal_alley)
          setPersonalLane(persnoal_data.personal_lane)
          setPersonalRoad(persnoal_data.personal_road)
          setSelectedPrefix(persnoal_data.personal_prefix.data.id)
          setSelectedProvince(persnoal_data.province.data.id)
          return (persnoal_data)
        })
        console.log('persnoal_data2', persnoal_data2);

        await getAmphurs(persnoal_data2.province.data.id).then(res => {
          let temp_list = []
          let res_data = res.data.data
          console.log('res_data', res_data);
          setData(res_data)

          for (let row_data of res_data) {
            temp_list.push({
              id: row_data.id,
              name: row_data.attributes.amphur_name
            })
          }
          setAmphurList(temp_list)
          setSelectedAmphur(persnoal_data2.amphur.data.id)
        })

        await getTambons(persnoal_data2.amphur.data.id).then(res => {
          let temp_list = []
          let res_data = res.data.data
          console.log('tambon data', res_data);
          for (let row_data of res_data) {
            temp_list.push({
              id: row_data.id,
              name: row_data.attributes.tambon_name
            })
          }
          setTambonList(temp_list)
          setSelectedTambon(persnoal_data2.tambon.data.id)
        })

        if (persnoal_data2.farmer.data === null) {
          setIsFarmer(false)
        }
        else {
          setIsFarmer(true)
          setFarmerID(persnoal_data2.farmer.data.id)
          await callGetService("farms/" + persnoal_data2.farmer.data.id).then(res => {
            let res_data = res.data.data
            console.log('res_data_table', res_data);
            let temp_list = []
            let index = 0;
            for (let row_data of res_data) {
              index++;
              temp_list.push({
                id: row_data.id,
                index: index,
                farm_name: row_data.attributes.farm_name,
                farm_rai: row_data.attributes.farm_rai,
                farm_nga: row_data.attributes.farm_nga,
                farm_wa: row_data.attributes.farm_wa,
                farm_area: row_data.attributes.farm_area,
                farm_geo_fence: row_data.attributes.farm_geo_fence,
                is_active: 1
              })
            }
            setRowsData(temp_list)
          })
        }

        //TODO
        // const response = await callGetService("farms/" + farmer_id)
        // console.log('response', response)

        // console.log('>>>response', response.data.data);
        //   let temp_list = []
        //   let index = 0;
        //   for (let row_data of res_data) {
        //     index++;
        //     temp_list.push({
        //       id: row_data.id,
        //       index: index,
        //       farm_name: row_data.attributes.farm_name,
        //       farm_rai: row_data.attributes.farm_rai,
        //       farm_nga: row_data.attributes.farm_nga,
        //       farm_wa: row_data.attributes.farm_wa,
        //       farm_area: row_data.attributes.farm_area,
        //       is_active: 1
        //     })
        //   }
        //   setRowsData(temp_list)
        // })

        // console.log('response', response.data)
        // setGeojsonData(response.data);

      } catch (error) {
        console.error('Error fetching data from Server:', error);
        alert('Error fetching data from Server')
      }
    };

    fetchData();
  }, []);

  const handleSaveFarmButton = () => {
    if (farm_name === "") {
      alert("คุณยังไม่ได้ระชุชื่อแปลง \n กรุณาระบุชื่อแปลง")
    } else {
      console.log('asdjasdas;dkadkas;ldkal;dk')
      console.log('mapLayers',mapLayers)
      let data = {
        data: {
          farm_name: farm_name,
          farm_rai: farm_rai,
          farm_nga: farm_nga,
          farm_wa: farm_wa,
          farm_area: farm_area,
          farm_geo_fence: mapLayers,
          farmer: farmer_id
        }
      }

      console.log(data)

      if (is_edit) {
        callPutService("farms/" + selected_farm, data).then(res => {
          // setIsRefresh(!is_refresh)
          // setIsModalOpen(false)
          window.location.reload();
        })
      } else {
        callPostService("farms", data).then(res => {
          // setIsRefresh(!is_refresh)
          // setIsModalOpen(false)
          window.location.reload();
        })
      }
    }
  }


  const [promise, setPromise] = useState(false)
  if (rows_data.length === 0 && !promise) {
    setTimeout(function () {
      setPromise(!promise)
    }, 2000);
    return <Loading /> // Display a loading message or spinner
  }

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>แก้ไขข้อมูลส่วนบุคคล</Typography>
        </Grid>
        <Grid item xs={12} >
          <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_prefix}
                    variant="outlined"
                    fullWidth
                    onChange={event => setSelectedPrefix(event.target.value)}
                  >
                    <MenuItem key={0} value={0}>กรุณาเลือกคำนำหน้าชื่อ</MenuItem>
                    {prefix_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ชื่อ"
                    variant="outlined"
                    value={personal_name}
                    onChange={event => setPersonalName(event.target.value)}
                  />
                </Grid>

                <Grid item  xs={12} md={4.5}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="นามสกุล"
                    variant="outlined"
                    value={personal_surname}
                    onChange={event => setPersonalSurname(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="เลขบัตรประชาชน"
                    variant="outlined"
                    value={personal_id}
                    onChange={event => setPersonalID(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="วันเกิด"
                    type="date"
                    variant="outlined"
                    value={personal_birthday}
                    onChange={event => setPersonalBirthday(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="เบอร์โทรศัพท์"
                    variant="outlined"
                    value={personal_phone}
                    onChange={event => setPersonalPhone(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  {
                    is_farmer ?
                      <FormControlLabel
                        disabled
                        control={
                          <Switch
                            checked={is_farmer}
                            onChange={handleChangeToggle}
                          />
                        }
                        label="ลงทะเบียนชาวนา"
                      /> :
                      <FormControlLabel
                        control={
                          <Switch
                            checked={is_farmer}
                            onChange={handleChangeToggle}
                          />
                        }
                        label="ลงทะเบียนชาวนา"
                      />
                  }

                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="บ้านเลขที่"
                    variant="outlined"
                    value={personal_addr}
                    onChange={event => setPersonalAddr(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="หมู่"
                    variant="outlined"
                    value={personal_moo}
                    onChange={event => setPersonalMoo(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ตรอก"
                    variant="outlined"
                    value={personal_alley}
                    onChange={event => setPersonalAlley(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ซอย"
                    variant="outlined"
                    value={personal_lane}
                    onChange={event => setPersonalLane(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ถนน"
                    variant="outlined"
                    value={personal_road}
                    onChange={event => setPersonalRoad(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={3} lg={2}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_tombon}
                    variant="outlined"
                    label="ตำบล"
                    fullWidth
                  // onChange={event => setSelectedTambon(event.target.value)}
                  // onChange={(event) => {
                  //   handleChangeAmphur(event.target.value);
                  // }}
                  >
                    <MenuItem key={0} value={0}>{tambon_default}</MenuItem>
                    {tambon_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_amphur}
                    variant="outlined"
                    label="อำเภอ"
                    fullWidth
                    onChange={(event) => {
                      // handleChangeAmphur(event.target.value);
                    }}
                  >
                    <MenuItem key={0} value={0}>{amphur_default}</MenuItem>
                    {amphur_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>

                <Grid item xs={6} md={3} lg={2}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_province}
                    variant="outlined"
                    label="จังหวัด"
                    fullWidth
                    onChange={(event) => {
                      // handleChangeProvince(event.target.value);
                    }}
                  >
                    <MenuItem key={0} value={0}>กรุณาเลือกจังหวัด</MenuItem>
                    {province_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>
                {
                  !is_farmer ? null :
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={2}
                      // direction="row"
                      // justifyContent="flex-end"
                      // alignItems="center"
                      >
                        <Grid item>
                          <Typography variant='h6' >รายละเอียดแปลง</Typography>
                        </Grid>
                        <Grid item ></Grid>
                        <Grid item>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={(event) => { setMapLayers([]); setIsModalOpen(true); setIsEdit(false); setFarmName(''); setFarmRai(0); setFarmNga(0); setFarmWa(0); setFarmArea(0); }}
                          >
                            เพิ่มแปลง
                          </Button>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: '10px' }}
                      >
                        <Grid item xs={12}>
                          <div style={{ width: '100%' }}>
                            <DataGrid
                              style={{ backgroundColor: "#FFFFFF" }}
                              rows={rows_data}
                              columns={farm_columns}
                              // rowsPerPageOptions={[100]}
                              autoHeight
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                }
              </Grid>
            </CardContent>

            <CardActions>
              <Grid
                margin={1}
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleSaveButton}
                  >
                    แก้ไขข้อมูล
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleBackButton}
                  >
                    ย้อนกลับ
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>

      <Modal
        open={is_modal_open}
        onClose={(event) => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Card>
            <CardContent> */}
          <Typography variant="h5" component="div">
            {is_edit ? 'แก้ไขแปลง' : 'เพิ่มแปลง'}
          </Typography>
          <br />
          <MapComponent layers={mapLayers} onEdit={is_edit} onData={handleDataFromChild} onData2={handleDataFromChild2} />

          <Grid container spacing={2} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="ชื่อแปลง"
                variant="outlined"
                value={farm_name}
                onChange={event => setFarmName(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="ไร่"
                variant="outlined"
                value={farm_rai}
                type="number"
                onChange={event => setFarmRai(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="งาน"
                variant="outlined"
                value={farm_nga}
                type="number"
                onChange={event => setFarmNga(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="วา"
                variant="outlined"
                value={farm_wa}
                type="number"
                onChange={event => setFarmWa(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="พื้นที่ (ตารางเมตร)"
                variant="outlined"
                value={farm_area}
                onChange={event => setFarmArea(event.target.value)}
              />
            </Grid>
          </Grid>
          {/* </CardContent>
            <CardActions> */}
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item ></Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={() => { handleSaveFarmButton() }}
              >
                {is_edit ? 'แก้ไขแปลง' : 'เพิ่มแปลง'}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => setIsModalOpen(false)}
              >
                ยกเลิก
              </Button>
            </Grid>
          </Grid>
          {/* </CardActions>
          </Card> */}
        </Box>
      </Modal>
    </div>

  )
}

function MapComponent(props) {
  const [polygonArea, setPolygonArea] = useState(0);
  const [center, setCenter] = useState([13.7367, 100.5231])
  const [mapLayers, setMapLayers] = useState([props.layers ? props.layers : []])

  console.log(mapLayers)

  const sendDataToParent = (polygonArea) => {
    const data = polygonArea
    props.onData(data);
  };

  const sendDataToParent2 = (mapLayers) => {
    const data = mapLayers
    props.onData2(data)
  };

  const ZOOM_LEVEL = 13
  const mapRef = useRef(null);

  const calculatePolygonArea = (polygonCoords) => {
    console.log(polygonCoords);
    if (polygonCoords.length > 0) {
      const updatedCoords = [...polygonCoords];
      updatedCoords.push(polygonCoords[0]);

      const turfPolygon = polygon([updatedCoords.map(({ lat, lng }) => [lng, lat])]);
      const polygonArea = area(turfPolygon);
      setPolygonArea(polygonArea);
      sendDataToParent(polygonArea)
    } else {
      console.log('0');
      setPolygonArea(0);
    }
  };

  const _onCreate = e => {
    console.log(e);

    const { layerType, layer } = e;
    if (layerType === "polygon") {
      const { _leaflet_id } = layer;

      setMapLayers((layers) => [
        ...layers,
        { id: _leaflet_id, latlng: layer.getLatLngs()[0] }]);
    }
    calculatePolygonArea(layer.getLatLngs()[0])
    sendDataToParent2(layer.getLatLngs()[0])

  }

  const _onEdited = e => {
    console.log(e)

    const { layers: { _layers } } = e;
    Object.values(_layers).map(({ _leaflet_id, editing }) => {
      setMapLayers((layers) =>
        layers.map((l) =>
          l.id === _leaflet_id ? { ...l, latlng: { ...editing.latlngs[0] } } : l
        )
      );
    });

    const latLngs = Object.values(_layers)[0].editing.latlngs[0];
    calculatePolygonArea(latLngs[0])
  };

  const _onDeleted = e => {
    console.log(e)

    const { layers: { _layers } } = e;
    Object.values(_layers).map((_leaflet_id) => {
      setMapLayers(layers => layers.filter(l => l.id !== _leaflet_id));
    })

    setPolygonArea(0)
    sendDataToParent(0)
  }


  const [isButtonClicked, setIsButtonClicked] = useState(false);

  const handleButtonClick = (event) => {
    if (!isButtonClicked) {
      setMapLayers([[]]);
      setIsButtonClicked(true);
    }
  };

  return (
    <div>
      {console.log(props.onEdit)}
      {props.onEdit && props.layers && (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-3rem', }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleButtonClick}
            disabled={isButtonClicked}
          >
            ลบแปลง
          </Button>
        </div>
      )}
      <br />
      <MapContainer
        center={center}
        zoom={ZOOM_LEVEL}
        style={{ width: '100%', height: '350px' }}
        ref={mapRef}
      >
        <FeatureGroup>
          <EditControl position='topright'
            onCreated={_onCreate}
            onEdited={_onEdited}
            onDeleted={_onDeleted}
            draw={{
              rectangle: false,
              polyline: false,
              circle: false,
              circlemarker: false,
              marker: false,
            }}

          />
        </FeatureGroup>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="Map data © OpenStreetMap contributors" />
        <Polygon pathOptions={{ color: 'purple' }} positions={mapLayers} />
        {/* <div class="leaflet-control-attribution leaflet-control bott"><a href="https://leafletjs.com" title="A JS library for interactive maps">Leaflet</a> | © <a href="https://www.maptiler.com/">MapTiler</a> © <a href="http://osm.org/copyright">OpenStreetMap</a> contributors</div> */}
        {/* <LocationMarker /> */}
      </MapContainer>
      {/* <pre className='text-left'>{JSON.stringify(mapLayers, 0, 2)}</pre> */}
    </div >
  );
}

// function LocationMarker() {
//   const [position, setPosition] = useState(null)
//   const map = useMapEvents({
//     click() {
//       map.locate()
//     },
//     locationfound(e) {
//       setPosition(e.latlng)
//       map.flyTo(e.latlng, map.getZoom())
//     },
//   })

//   return position === null ? null : (
//     <Marker position={position}>
//       <Popup>You are here</Popup>
//     </Marker>
//   )
// }
