import axios from 'axios';
import config from '../config.json';
import LocalStorage from '../utils/LocalStorage';

export const addPersonal = async (obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.post(
    config.BASE_API + "personals",
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

export const editPersonal = async (id, obj) => {
  let token = LocalStorage.getAccessToken();
  return axios.put(
    config.BASE_API + "personals/"+id,
    obj,
    {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      }
    }
  )
}

// export const 