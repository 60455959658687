import React, { useState, useEffect } from 'react';
import AdminLayout from '../../components/admin/AdminLayout'
// import PaFForm from '../../components/PaFForm';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { v4 as uuid } from 'uuid';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from '@mui/material';

import {
  addPersonal
} from '../../services/Personal'

import {
  getPrefix,
  getProvince,
  getAmphurs,
  getTambons
} from '../../services/Dropdown'
import { useAsyncError } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function PaFAdd() {
  // form variable
  const [personal_id, setPersonalID] = useState("");
  const [prefix_list, setPrefixList] = useState([]);
  const [selected_prefix, setSelectedPrefix] = useState(0);
  const [personal_name, setPersonalName] = useState("");
  const [personal_surname, setPersonalSurname] = useState("");
  const [personal_birthday, setPersonalBirthday] = useState("");
  const [personal_phone, setPersonalPhone] = useState("");
  const [is_farmer, setIsFarmer] = useState(false);
  const [personal_addr, setPersonalAddr] = useState("");
  const [personal_moo, setPersonalMoo] = useState("");
  const [personal_alley, setPersonalAlley] = useState("");
  const [personal_lane, setPersonalLane] = useState("");
  const [personal_road, setPersonalRoad] = useState("");
  const [tambon_default, setTambonDefault] = useState("กรุณาเลือกจังหวัด");
  const [tambon_list, setTambonList] = useState([]);
  const [selected_tombon, setSelectedTambon] = useState(0);
  const [amphur_default, setAmphurDefault] = useState("กรุณาเลือกจังหวัด");
  const [amphur_list, setAmphurList] = useState([]);
  const [selected_amphur, setSelectedAmphur] = useState(0);
  const [province_list, setProvinceList] = useState([]);
  const [selected_province, setSelectedProvince] = useState(0);

  const handleBackButton = () => window.location.href = "/paf/list";

  const handleChangeToggle = (event) => {
    setIsFarmer(event.target.checked);
  };

  const handleSaveButton = () => {
    if (selected_prefix === 0) {
      alert("คุณยังไม่ได้เลือกคำนำหน้าชื่อ \n กรุณาเลือกคำนำหน้าชื่อ")
    } else if (personal_name === "") {
      alert("คุณยังไม่ได้ระชุชื่อ \n กรุณาระบุชื่อ")
    } else if (personal_surname === "") {
      alert("คุณยังไม่ได้ระบุนามสกุล \n กรุณาระบุนามสกุล")
    } else if (personal_id === "") {
      alert("คุณยังไม่ได้ระบุรหัสประชาชน \n กรุณาระบุรหัสประชาชน")
    } else if (personal_addr === "") {
      alert("คุณยังไม่ได้ระบุบ้านเลขที่ \n กรุณาระบุบ้านเลขที่")
    } else if (selected_tombon === 0) {
      alert("คุณยังไม่ได้เลือกตำบล \n กรุณาเลือกตำบล")
    } else if (selected_amphur === 0) {
      alert("คุณยังไม่ได้เลือกอำเภอ \n กรุณาเลือกอำเภอ")
    } else if (selected_province === 0) {
      alert("คุณยังไม่ได้เลือกจังหวัด \n กรุณาเลือกจังหวัด")
    } else if (personal_birthday === "") {
      alert("คุณยังไม่ได้ระบุวันเกิด \n กรุณาระบุวันเกิด")
    } else {
      let add_object = {
        data: {
          "personal_uid": uuid(),
          "personal_id": personal_id,
          "personal_prefix": selected_prefix,
          "personal_name": personal_name,
          "personal_surname": personal_surname,
          "personal_birthday": personal_birthday,
          "personal_phone": personal_phone,
          "personal_addr": personal_addr,
          "personal_moo": personal_moo,
          "personal_alley": personal_alley,
          "personal_lane": personal_lane,
          "personal_road": personal_road,
          "tambon": selected_tombon,
          "amphur": selected_amphur,
          "province": selected_province
        }
      }
      addPersonal(add_object).then(res => {
        alert("เพิ่มข้อมูลสำเร็จ")
        window.location.href = "/paf/list";
      })
    }
  }

  const handleChangeProvince = (value) => {
    setSelectedProvince(value);
    if (value === 0) {
      setAmphurDefault("กรุณาเลือกจังหวัด");
      setTambonDefault("กรุณาเลือกจังหวัด");
      setSelectedAmphur(0);
      setSelectedTambon(0)
    } else {
      setAmphurDefault("กรุณาเลือกอำเภอ");
      setTambonDefault("กรุณาเลือกอำเภอ");
      getAmphurs(value).then(res => {
        let temp_list = []
        let res_data = res.data.data
        console.log(res_data);
        for (let row_data of res_data) {
          temp_list.push({
            id: row_data.id,
            name: row_data.attributes.amphur_name
          })
        }
        setAmphurList(temp_list)
      })
    }
  }

  const handleChangeAmphur = (value) => {
    setSelectedAmphur(value);
    if (value === 0) {
      setTambonDefault("กรุณาเลือกอำเภอ");
      setSelectedTambon(0)
    } else {
      setTambonDefault("กรุณาเลือกตำบล");
      getTambons(value).then(res => {
        let temp_list = []
        let res_data = res.data.data
        console.log(res_data);
        for (let row_data of res_data) {
          temp_list.push({
            id: row_data.id,
            name: row_data.attributes.tambon_name
          })
        }
        setTambonList(temp_list)
      })
    }

  }

  useEffect(async () => {
    getPrefix().then(res => {
      let temp_prefix_list = []
      let prefix_res_data = res.data.data
      for (let prefix_row_data of prefix_res_data) {
        temp_prefix_list.push({
          id: prefix_row_data.id,
          name: prefix_row_data.attributes.prefix_name
        })
      }
      setPrefixList(temp_prefix_list)
    })

    getProvince().then(res => {
      let temp_list = []
      let res_data = res.data.data
      for (let row_data of res_data) {
        temp_list.push({
          id: row_data.id,
          name: row_data.attributes.province_name
        })
      }
      setProvinceList(temp_list)
    })
  }, []);

  // table variable
  const [rows_data, setRowsData] = useState([])
  const columns = []

  // modal vaiable
  const [is_modal_open, setIsModalOpen] = useState(false);
  const [farm_name, setFarmName] = useState("");
  const [farm_rai, setFarmRai] = useState(0);
  const [farm_nga, setFarmNga] = useState(0);
  const [farm_wa, setFarmWa] = useState(0);
  const [farm_area, setFarmArea] = useState(0);

  // const handleRaiChange = (value) => {
  //   setFarmRai(value);
  //   let lasted_area = farm_area;
  //   lasted_area += value * 1600
  //   setFarmArea(lasted_area)
  // }

  // const handleNgaChange = (value) => {
  //   setFarmNga(value);
  //   let lasted_area = farm_area;
  //   lasted_area += value * 400
  //   setFarmArea(lasted_area)
  // }

  // const handleWaChange = (value) => {
  //   setFarmWa(value);
  //   let lasted_area = farm_area;
  //   lasted_area += value * 4
  //   setFarmArea(lasted_area)
  // }

  const handleSaveFarmButton = () => {
    // if(farm_name)
  }

  return (
    <div style={{ margin: '2rem' }}>
      <AdminLayout />
      <Grid container style={{ marginTop: '4rem', marginBottom: '2rem' }}>
        <Grid item xs={12}>
          <Typography variant='h4' style={{ fontWeight: 'bold' }}>เพิ่มข้อมูลส่วนบุคคล</Typography>
        </Grid>
        <Grid item xs={12} >
          <Card style={{ marginTop: '1.5rem', marginRight: '1%', marginLeft: '1%' }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12} md={3}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_prefix}
                    variant="outlined"
                    label="คำนำหน้าชื่อ"
                    fullWidth
                    onChange={event => setSelectedPrefix(event.target.value)}
                  >
                    <MenuItem key={0} value={0}>กรุณาเลือกคำนำหน้าชื่อ</MenuItem>
                    {prefix_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12}md={4.5}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ชื่อ"
                    variant="outlined"
                    value={personal_name}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalName(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="นามสกุล"
                    variant="outlined"
                    value={personal_surname}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalSurname(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="เลขบัตรประชาชน"
                    variant="outlined"
                    value={personal_id}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalID(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="วันเกิด"
                    type="date"
                    variant="outlined"
                    value={personal_birthday}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalBirthday(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="เบอร์โทรศัพท์"
                    variant="outlined"
                    value={personal_phone}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalPhone(event.target.value)}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={is_farmer}
                        onChange={handleChangeToggle}
                      />
                    }
                    label="ลงทะเบียนชาวนา"
                  />
                </Grid> */}
                <Grid item xs={12} md={2.4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="บ้านเลขที่"
                    variant="outlined"
                    value={personal_addr}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalAddr(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={2.4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="หมู่"
                    variant="outlined"
                    value={personal_moo}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalMoo(event.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={2.4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ตรอก"
                    variant="outlined"
                    value={personal_alley}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalAlley(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={2.4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ซอย"
                    variant="outlined"
                    value={personal_lane}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalLane(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={2.4}>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label="ถนน"
                    variant="outlined"
                    value={personal_road}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={event => setPersonalRoad(event.target.value)}
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_tombon}
                    variant="outlined"
                    label="ตำบล"
                    fullWidth
                    onChange={event => setSelectedTambon(event.target.value)}
                  // onChange={(event) => {
                  //   handleChangeAmphur(event.target.value);
                  // }}
                  >
                    <MenuItem key={0} value={0}>{tambon_default}</MenuItem>
                    {tambon_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_amphur}
                    variant="outlined"
                    label="อำเภอ"
                    fullWidth
                    onChange={(event) => {
                      handleChangeAmphur(event.target.value);
                    }}
                  >
                    <MenuItem key={0} value={0}>{amphur_default}</MenuItem>
                    {amphur_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selected_province}
                    variant="outlined"
                    label="จังหวัด"
                    fullWidth
                    onChange={(event) => {
                      handleChangeProvince(event.target.value);
                    }}
                  >
                    <MenuItem key={0} value={0}>กรุณาเลือกจังหวัด</MenuItem>
                    {province_list.map((item, index) => {
                      return (<MenuItem key={index + 1} value={item.id}>{item.name}</MenuItem>)
                    })}
                  </Select>
                </Grid>
                {
                  !is_farmer ? null :
                    <Grid item xs={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <Typography variant='h6' >รายละเอียดแปลง</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={(event) => setIsModalOpen(true)}
                          >
                            เพิ่มแปลง
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <div style={{ height: 400, width: '100%' }}>
                            <DataGrid
                              style={{ backgroundColor: "#FFFFFF" }}
                              rows={rows_data}
                              columns={columns}
                              rowsPerPageOptions={[]}
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                }
              </Grid>
            </CardContent>
            <CardActions>
              <Grid
                container
                spacing={2}
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item ></Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={handleSaveButton}
                  >
                    เพิ่มข้อมูล
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={handleBackButton}
                  >
                    ย้อนกลับ
                  </Button>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
      <Modal
        open={is_modal_open}
        onClose={(event) => setIsModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <Card>
            <CardContent> */}
          <Typography variant="h5" component="div">
            เพิ่มแปลง
          </Typography>
          <Grid container spacing={2} style={{ marginTop: '2rem', marginBottom: '2rem' }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="ชื่อแปลง"
                variant="outlined"
                value={farm_name}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => setFarmName(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="ไร่"
                variant="outlined"
                value={farm_rai}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => setFarmRai(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="งาน"
                variant="outlined"
                value={farm_nga}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => setFarmNga(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="วา"
                variant="outlined"
                value={farm_wa}
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => setFarmWa(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="พื้นที่ (ตารางเมตร)"
                variant="outlined"
                value={farm_area}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={event => setFarmArea(event.target.value)}
              />
            </Grid>
          </Grid>
          {/* </CardContent>
            <CardActions> */}
          <Grid
            container
            spacing={2}
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Grid item ></Grid>
            <Grid item>
              <Button
                variant="contained"
                color="success"
                onClick={handleSaveFarmButton}
              >
                เพิ่มแปลง
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="error"
                onClick={(event) => setIsModalOpen(false)}
              >
                ยกเลิก
              </Button>
            </Grid>
          </Grid>
          {/* </CardActions>
          </Card> */}
        </Box>
      </Modal>
    </div>

  )
}